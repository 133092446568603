import { Fragment } from 'react'
import { css } from '@emotion/react'
import { Paper, Typography } from '@mui/material'
import React, { memo } from 'react'
import { Socket } from '~/modules/SDK/socket2/Socket'
import { WHITE } from '~/modules/AppLayout/Colors'
import { ai, flex, pureGrid } from '~/modules/AppLayout/FlexGridCss'
import { Change } from '~/utils/processQuoteToReadable'
import { Signalr } from '../Signalr/Signalr'
import IntradayTrendChart from '~/modules/trendChart'
import { toReadableUnitChinese } from '~/utils/representChineseNumberFormat'
import { fontWeight600 } from '~/css/font'
import { getPercentagDeltaColor } from '~/modules/AppLayout/colorUtils'
import { SymbolReadable } from '~/modules/SDK/Symbol/SymbolReadable'

const QuoteRow = memo<
  ReactProps<{
    text: string
    price: number
    change: number
    changePercent: number
  }>
>(function QuoteRow(props) {
  return (
    <Fragment>
      <Typography variant='h6'>{props.text}</Typography>
      <Typography
        align='right'
        variant='body1'
      >
        {props.price < 10000000 ? props.price : toReadableUnitChinese(props.price)}
      </Typography>

      <Typography
        align='right'
        variant='body1'
        css={css`
          color: ${getPercentagDeltaColor(props.changePercent)};
        `}
      >
        {isNaN(props.change) ? '' : `${Math.sign(props.change) === 1 ? '+' : ''}${props.change}`}
      </Typography>
      <Typography
        align='right'
        variant='body1'
        css={css`
          color: ${getPercentagDeltaColor(props.changePercent)};
        `}
      >
        {isNaN(props.changePercent)
          ? ''
          : `${Math.sign(props.changePercent) === 1 ? '+' : ''}${props.changePercent}%`}
      </Typography>
    </Fragment>
  )
})

const symbolCss = css`
  font-size: 20px;
  margin: 4px 0;
  ${fontWeight600}
`

/** 滑動到Quote磚，出現的詳細資訊 */
export const QuoteBoxToolTip = memo<
  React.PropsWithChildren<{
    quoteChanges: Change
    data: Socket.ValueOfOHLC | Signalr.ValueOfOHLC
    enableChart?: boolean
    enableTradeValue?: boolean
    enableVolume?: boolean
    intraday?: boolean
  }>
>(function SymbolTooltip(props) {
  const { quoteChanges, data } = props
  return (
    <Paper
      css={css`
        ${pureGrid};
        grid-template-rows: auto 32px 32px 32px 32px;
        grid-template-columns: 48px 72px 72px 72px;
        border: 2px solid ${'#222222'};
        align-items: center;
        padding: 0 8px;
        margin: -16px;
        & > * {
          ${fontWeight600} !important;
        }
      `}
    >
      <div
        css={css`
          ${flex.h.mainCenter};
          ${ai.stretch}
          color: ${WHITE};
          grid-row: 1;
          grid-column: 1/5;
          margin: -8px -10px 0;
          background: ${getPercentagDeltaColor(quoteChanges?.closeChangePercent)};
        `}
      >
        <p css={symbolCss}>
          <SymbolReadable symbol={data.symbol} />
        </p>
      </div>
      <QuoteRow
        text={'開'}
        price={data?.open}
        change={quoteChanges?.openChange}
        changePercent={quoteChanges?.openChangePercent || 0}
      />
      <QuoteRow
        text='高'
        price={data?.high}
        change={quoteChanges?.highChange}
        changePercent={quoteChanges?.highChangePercent || 0}
      />
      <QuoteRow
        text='彽'
        price={data?.low}
        change={quoteChanges?.lowChange}
        changePercent={quoteChanges?.lowChangePercent || 0}
      />
      <QuoteRow
        text={'收'}
        price={data?.close}
        change={quoteChanges?.closeChange}
        changePercent={quoteChanges?.closeChangePercent || 0}
      />
      {props.enableVolume ? (
        <QuoteRow
          text='量'
          price={data?.volume}
          change={NaN}
          changePercent={NaN}
        />
      ) : null}
      {props.enableTradeValue ? (
        <QuoteRow
          text='值'
          price={data?.close * data?.volume * 1000}
          change={NaN}
          changePercent={NaN}
        />
      ) : null}
      {props.enableChart === true ? (
        <div
          css={css`
            width: 240px;
            height: 160px;
            margin-top: 8px;
            padding: 8px;
            border: 1px solid rgba(0, 0, 0, 0.1);
          `}
        >
          <IntradayTrendChart
            symbol={props.data.symbol as Socket.SymbolString}
            ticksSize={12}
            ticksHeight={16}
            transparency={0.3}
            priceTicksMargin={-20}
            priceTicksSize={10}
            intraday={props.intraday}
          />
        </div>
      ) : null}
    </Paper>
  )
})
