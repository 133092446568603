import React, { memo } from 'react'
import { SymbolName } from '~/modules/SDK/Symbol/SymbolName'
import { isOpbsSymbol, opbsAdvancedTranslator } from '~/utils/optionsTranslator'

export const SymbolReadable = memo<ReactProps<{ symbol: string }>>(function SymbolReadable({
  symbol,
}) {
  return (
    <>{isOpbsSymbol(symbol) ? opbsAdvancedTranslator(symbol) : <SymbolName symbol={symbol} />}</>
  )
})
