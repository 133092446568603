import { css } from '@emotion/react'
import React, { memo } from 'react'
import { HighlightText } from '~/components/HighlightText'
import { flex } from '~/modules/AppLayout/FlexGridCss'

const colors = ['#30cc5a', '#2f9e4f', '#35764e', '#414554', '#8b444e', '#bf4045', '#f63538']
const percentage = ['-3%', '-2%', '-1%', '0', '1%', '2%', '3%']
const futuresPercentage = ['-2%', '-1%', '-0.3%', '0', '0.3%', '1%', '2%']

/** %單位個代表的顏色 */
export const PercentagePalette = memo<ReactProps<{ isFutures?: boolean }>>(
  function PercentagePalette(props) {
    return (
      <div
        css={css`
          ${flex.h.default}
          & > * {
            margin-left: 2px;
            align-self: center;
          }
        `}
      >
        {colors.map((color, index) => (
          <HighlightText
            key={index}
            background={color}
            content={props.isFutures ? futuresPercentage[index] : percentage[index]}
          />
        ))}
      </div>
    )
  },
)
