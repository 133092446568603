import React, { memo } from 'react'
import useMedia from '~/hooks/useMedia'
import { CandleBarChart } from '~/modules/SDK/socket/CandleBarChart'
import { useWeightedStocksPoolState } from '~/modules/monitors/useWeightedStocksPoolState'
import { useSnapshot } from 'valtio'
import { useMonitorSelectionStore } from './useMonitorSelectionStore'

export const StockCandleBarChart = memo<
  ReactProps<{
    hoverDelegate: React.Dispatch<React.SetStateAction<string>>
  }>
>(function StotckCandleBarChart(props) {
  const { isPhone } = useMedia()
  const { state } = useWeightedStocksPoolState.useContainer()
  const snap = useSnapshot(useMonitorSelectionStore)
  const symbols = state.symbols.map(s => s.symbol).slice(0, snap.symbolCount)
  return (
    <>
      {/* WORKAROUND: 原先是以isPhone來判斷給予symbols陣列，會造成hook問題 */}
      {/* WORKAROUND: 因此分兩種顯示，否則在切換symbols數量時，CandleBarChart建立的hook會有不一致的問題 */}
      <CandleBarChart
        hoverDelegate={props.hoverDelegate}
        symbols={symbols}
        shouldCustomizedXLabel={true}
        hide={isPhone}
      />
      <CandleBarChart
        hoverDelegate={props.hoverDelegate}
        symbols={isPhone ? symbols.slice(0, 20) : symbols}
        shouldCustomizedXLabel={true}
        hide={!isPhone}
      />
    </>
  )
})
