export const toCommaSeparatedNumber = (value: number | string | null | undefined, digit = 2) => {
  let num
  if (typeof value === 'string') {
    num = parseFloat(value)
  } else if (typeof value === 'object' || typeof value === 'undefined') {
    num = NaN
  } else {
    num = value
  }
  return num.toLocaleString('en-US')
}

export const toReadableNumber = (value: number | string | null | undefined, digit = 2) => {
  let num
  if (typeof value === 'string') {
    num = parseFloat(value)
  } else if (typeof value === 'object' || typeof value === 'undefined') {
    num = NaN
  } else {
    num = value
  }
  return parseFloat(num?.toFixed(digit))
}

export const toReadableUnit = (value: string | number) => {
  const num = typeof value === 'string' ? parseInt(value) : value
  const absoluteValue = Math.abs(num)
  return (
    (num < 0 ? '-' : '') +
    (absoluteValue >= 1000000
      ? (absoluteValue / 1000000).toFixed(1) + 'M'
      : absoluteValue >= 1000
      ? (absoluteValue / 1000).toFixed(1) + 'K'
      : absoluteValue)
  )
}

export const toReadableUnitChinese = (value: string | number) => {
  const num = typeof value === 'string' ? parseInt(value) : value
  const absoluteValue = Math.abs(num)
  return (
    (num < 0 ? '-' : '') +
    (absoluteValue >= 100000000
      ? (absoluteValue / 100000000).toFixed(absoluteValue % 100000000 === 0 ? 0 : 1) + '億'
      : absoluteValue >= 10000000
      ? (absoluteValue / 10000000).toFixed(absoluteValue % 10000000 === 0 ? 0 : 1) + '千萬'
      : absoluteValue >= 1000000
      ? (absoluteValue / 1000000).toFixed(absoluteValue % 1000000 === 0 ? 0 : 1) + '百萬'
      : absoluteValue >= 10000
      ? (absoluteValue / 10000).toFixed(absoluteValue % 10000 === 0 ? 0 : 1) + '萬'
      : absoluteValue)
  )
}
