import { useStockWeightResource } from '~/modules/chips/useChipResource'
import { createContainer } from '~/modules/unstated-next-utils/createContainer'
import { useMemo, useState } from 'react'
import { useOTC50StockList, useTw50StockList } from '~/modules/SDK/Symbol/useCommonSymbolList'

/** 上市櫃權重 */
export const useWeightedStocksPool = () => {
  const [isTpex, setIsTpex] = useState(false)
  const twse = useStockWeightResource('TWSE')
  const tpex = useStockWeightResource('TPEX')
  // 上市top 50 名單
  const twse50 = useTw50StockList()

  // 上櫃top 50 名單
  const tpex50 = useOTC50StockList()

  const orderedTWSEStocks = useMemo(() => {
    return twse
      .filter(([k]) => twse50?.indexOf(k) !== -1)
      .map(([k, v]) => ({ symbol: k, percentage: v.percentage }))
      .sort((a, b) => b.percentage - a.percentage)
  }, [twse, twse50])

  const orderedTPEXStocks = useMemo(
    () =>
      tpex
        .filter(([k]) => tpex50?.indexOf(k) !== -1)
        .map(([k, v]) => ({ symbol: k, percentage: v.percentage }))
        .sort((a, b) => b.percentage - a.percentage),
    [tpex, tpex50],
  )
  const all = orderedTWSEStocks.concat(orderedTPEXStocks)

  const getWeight = (symbol: string | undefined) =>
    0.01 * (all.find(s => s.symbol === symbol)?.percentage ?? 0.01)

  return {
    state: {
      isTpex,
      symbols: isTpex ? orderedTPEXStocks : orderedTWSEStocks,
    },
    acts: { getWeight, setIsTpex },
  }
}

export const useWeightedStocksPoolState = createContainer(useWeightedStocksPool)
