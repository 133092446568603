import { css } from '@emotion/react'
import { memo } from 'react'
import { fontWeight600 } from '~/css/font'

export const HighlightText = memo<
  React.PropsWithChildren<{
    color?: string
    background: string
    content?: string
    border?: string
  }>
>(function HighlightText(props) {
  const { background, color, content, border } = props
  return (
    <div
      css={css`
        background: ${background};
        color: ${color ?? 'white'};
        border: ${border ?? 'null'};
        padding: 2px 4px;
        min-width: 32px;
        border-radius: 4px;
        text-align: center;
        ${fontWeight600};
      `}
    >
      {content}
    </div>
  )
})
